import React from "react";
import { Link, graphql } from "gatsby";
import { css } from "@emotion/react";

const pageHeader = css`
  color: "#232129";
  font-family: -apple-system, "Roboto", sans-serif, serif;
  font-size: 24px;
  margin: 12px 8px;
`;

const container = css`
  display: flex;
  flex-flow: column;
  // margin: 24px;
  // padding-bottom: 16px;
  // padding: 16px;
  margin: 16px 24px 0;
`;

const titleStyle = css`
  color: darkslategray;
  font-size: 18px;
  font-family: -apple-system, "Roboto", sans-serif, serif;
  margin: 4px 0;
`;

const contentStyle = css`
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
  font-weight: normal;
`;

const dateStyle = css`
  color: slategray;
  font-size: 14px;
  font-weight: lighter;
  font-family: -apple-system, "Roboto", sans-serif, serif;
  margin: 4px;
`;

// const timeToReadStyle = css`
//   color: lightslategray;
//   font-size: 14px;
//   font-weight: lighter;
//   font-family: -apple-system, "Roboto", sans-serif, serif
//   font-style: italic;
//   margin-left: 12px;
// `;

const link = css`
  color: darkslategray;
  display: inline;
  // align-self: end;
  font-weight: normal;
  padding-bottom: 4px;
  text-decoration: none;
  font-family: "Times New Roman", Times, serif;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
`;

const PostPreviewTemplate = ({ props }) => {
  return (
    <div css={container}>
      <h4 css={titleStyle}>{props.frontmatter.title}</h4>
      <p css={dateStyle}>{props.frontmatter.date}</p>
      <div>
        <p
          css={contentStyle}
          dangerouslySetInnerHTML={{ __html: props.excerpt }}
        ></p>

        <Link css={link} to={props.frontmatter.slug}>
          read more
        </Link>
      </div>
      <hr
        css={css`
          margin-top: 16px;
          width: 100%;
        `}
      ></hr>
    </div>
  );
};

const PostsPage = ({ data }) => {
  const { allMarkdownRemark } = data;
  return (
    <main>
      <h2 css={pageHeader}>My Posts</h2>
      {allMarkdownRemark.nodes.map((post) => {
        return <PostPreviewTemplate key={post.id} props={post}></PostPreviewTemplate>;
      })}
    </main>
  );
};

export default PostsPage;

export const top10Posts = graphql`
  query Top10Posts {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/src/markdown/posts/*.md" } }
      limit: 10
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        frontmatter {
          title
          date(formatString: "MMM DD, YYYY")
          slug
        }
        excerpt(pruneLength: 300, format: HTML)
      }
    }
  }
`;
